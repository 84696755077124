
/* ----- Alerts ----- */

.has-error{
  .control-label{
    color: $blue;
  }
  .form-control,
  .form-control:focus{
    border-color: $blue;
    box-shadow: none;
  }
}

.help-block,
.has-error .help-block,
.create .help-block{
  background-color: $yellowLight;
  color: $greyDarker;
  display: inline-block;
  strong{
    color: $greyDarker;
    font-weight: 400;
  }
}


.alert-success {
  color: $black;
  background-color: $yellowLight;
  border: none;
}
