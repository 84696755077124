@charset "UTF-8";
/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/01-settings.scss */
.font-body, body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: .05em; }

/**
 * simplemde v1.11.2
 * Copyright Next Step Webs, Inc.
 * @link https://github.com/NextStepWebs/simplemde-markdown-editor
 * @license MIT
 */
/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror {
  color: #000; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-lines {
  padding: 4px 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror pre {
  padding: 0 4px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter-filler, .CodeMirror-scrollbar-filler {
  background-color: #fff; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-guttermarker {
  color: #000; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-guttermarker-subtle {
  color: #999; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-cursor {
  border-left: 1px solid #000;
  border-right: none;
  width: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7; }

@-moz-keyframes blink {
  50% {
    background-color: transparent; } }

@-webkit-keyframes blink {
  50% {
    background-color: transparent; } }

@keyframes blink {
  50% {
    background-color: transparent; } }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-tab {
  display: inline-block;
  text-decoration: inherit; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-header {
  color: #00f; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-quote {
  color: #090; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-negative {
  color: #d44; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-positive {
  color: #292; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-header, .cm-strong {
  font-weight: 700; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-em {
  font-style: italic; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-link {
  text-decoration: underline; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-strikethrough {
  text-decoration: line-through; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-keyword {
  color: #708; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-atom {
  color: #219; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-number {
  color: #164; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-def {
  color: #00f; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-variable-2 {
  color: #05a; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-variable-3 {
  color: #085; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-comment {
  color: #a50; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-string {
  color: #a11; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-string-2 {
  color: #f50; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-builtin {
  color: #30a; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-bracket {
  color: #997; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-tag {
  color: #170; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-attribute {
  color: #00c; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-hr {
  color: #999; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-s-default .cm-link {
  color: #00c; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-invalidchar, .cm-s-default .cm-error {
  color: red; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-composing {
  border-bottom: 2px solid; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0f0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f22; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3); }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-activeline-background {
  background: #e8f2ff; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: #fff; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-scroll {
  overflow: scroll !important;
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: 0;
  position: relative; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter-filler, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-vscrollbar {
  position: absolute;
  z-index: 6;
  display: none; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -30px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: 0 0 !important;
  border: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-lines {
  cursor: text;
  min-height: 1px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror pre {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: 0 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-code {
  outline: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber, .CodeMirror-scroll, .CodeMirror-sizer {
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-cursor {
  position: absolute; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-measure pre {
  position: static; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-focused div.CodeMirror-cursors, div.CodeMirror-dragcursors {
  visibility: visible; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-selected {
  background: #d9d9d9; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-focused .CodeMirror-selected, .CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-crosshair {
  cursor: crosshair; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4); }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-force-border {
  padding-right: .1px; }

@media print {
  /* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden; } }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.cm-tab-wrap-hack:after {
  content: ''; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
span.CodeMirror-selectedtext {
  background: 0 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror {
  height: auto;
  min-height: 300px;
  border: 1px solid #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  font: inherit;
  z-index: 1; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-scroll {
  min-height: 300px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-fullscreen {
  background: #fff;
  position: fixed !important;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 9; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror-sided {
  width: 50% !important; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar {
  position: relative;
  opacity: .6;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 0 10px;
  border-top: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar:after, .editor-toolbar:before {
  display: block;
  content: ' ';
  height: 1px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar:before {
  margin-bottom: 8px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar:after {
  margin-top: 8px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar:hover, .editor-wrapper input.title:focus, .editor-wrapper input.title:hover {
  opacity: .8; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar.fullscreen {
  width: 100%;
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  background: #fff;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 9; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar.fullscreen::before {
  width: 20px;
  height: 50px;
  background: -moz-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, white), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, white 0, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar.fullscreen::after {
  width: 20px;
  height: 50px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0)), color-stop(100%, white));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, white 100%);
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a {
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  color: #2c3e50 !important;
  width: 30px;
  height: 30px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a.active, .editor-toolbar a:hover {
  background: #fcfcfc;
  border-color: #95a5a6; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a:before {
  line-height: 30px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar i.separator {
  display: inline-block;
  width: 0;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #fff;
  color: transparent;
  text-indent: -10px;
  margin: 0 6px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a.fa-header-x:after {
  font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
  font-size: 65%;
  vertical-align: text-bottom;
  position: relative;
  top: 2px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a.fa-header-1:after {
  content: "1"; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a.fa-header-2:after {
  content: "2"; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a.fa-header-3:after {
  content: "3"; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a.fa-header-bigger:after {
  content: "▲"; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar a.fa-header-smaller:after {
  content: "▼"; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  pointer-events: none;
  background: #fff;
  border-color: transparent;
  text-shadow: inherit; }

@media only screen and (max-width: 700px) {
  /* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
  .editor-toolbar a.no-mobile {
    display: none; } }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-statusbar {
  padding: 8px 10px;
  font-size: 12px;
  color: #959694;
  text-align: right; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-statusbar span {
  display: inline-block;
  min-width: 4em;
  margin-left: 1em; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-preview, .editor-preview-side {
  padding: 10px;
  background: #fafafa;
  overflow: auto;
  display: none;
  box-sizing: border-box; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-statusbar .lines:before {
  content: 'lines: '; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-statusbar .words:before {
  content: 'words: '; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-statusbar .characters:before {
  content: 'characters: '; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 7; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-preview-side {
  position: fixed;
  bottom: 0;
  width: 50%;
  top: 50px;
  right: 0;
  z-index: 9;
  border: 1px solid #ddd; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-preview-active, .editor-preview-active-side {
  display: block; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-preview-side > p, .editor-preview > p {
  margin-top: 0; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-preview pre, .editor-preview-side pre {
  background: #eee;
  margin-bottom: 10px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.editor-preview table td, .editor-preview table th, .editor-preview-side table td, .editor-preview-side table th {
  border: 1px solid #ddd;
  padding: 5px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-tag {
  color: #63a35c; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-attribute {
  color: #795da3; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-string {
  color: #183691; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-selected {
  background: #d9d9d9; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-1 {
  font-size: 200%;
  line-height: 200%; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-2 {
  font-size: 160%;
  line-height: 160%; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-3 {
  font-size: 125%;
  line-height: 125%; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-4 {
  font-size: 110%;
  line-height: 110%; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-comment {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-link {
  color: #7f8c8d; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-url {
  color: #aab2b3; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-strikethrough {
  text-decoration: line-through; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .CodeMirror-placeholder {
  opacity: .5; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/02-simplemde.scss */
.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
  background: rgba(255, 0, 0, 0.15); }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-search--inline {
  float: left; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-results {
  display: block; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-results__option[aria-selected] {
  cursor: pointer; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--open .select2-dropdown {
  left: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-search--dropdown {
  display: block;
  padding: 4px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-search--dropdown.select2-search--hide {
  display: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-dropdown--below {
  border-top: none; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

/* line 1, /Users/constant/Repos/1010/resources/assets/admin/css/libs/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.44
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
/* line 6, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget {
  list-style: none; }

/* line 9, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu {
  margin: 2px 0;
  padding: 4px;
  width: 19em; }

@media (min-width: 768px) {
  /* line 15, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 992px) {
  /* line 20, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 1200px) {
  /* line 25, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

/* line 29, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute; }

/* line 35, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px; }

/* line 43, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px; }

/* line 50, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px; }

/* line 58, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px; }

/* line 65, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px; }

/* line 69, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px; }

/* line 73, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0; }

/* line 76, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0; }

/* line 79, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none; }

/* line 82, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0; }

/* line 90, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px; }

/* line 93, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours"; }

/* line 104, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes"; }

/* line 115, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours"; }

/* line 126, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes"; }

/* line 137, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours"; }

/* line 148, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes"; }

/* line 159, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM"; }

/* line 170, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker"; }

/* line 181, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today"; }

/* line 192, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center; }

/* line 195, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens"; }

/* line 206, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit; }

/* line 213, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%; }

/* line 218, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0; }

/* line 222, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 4px; }

/* line 227, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px; }

/* line 232, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px; }

/* line 235, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

/* line 241, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month"; }

/* line 252, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month"; }

/* line 263, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer; }

/* line 266, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #eeeeee; }

/* line 269, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px; }

/* line 274, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #777777; }

/* line 280, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px; }

/* line 285, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer; }

/* line 292, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #777777; }

/* line 296, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.today {
  position: relative; }

/* line 299, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #337ab7;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

/* line 310, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

/* line 316, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff; }

/* line 319, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

/* line 325, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px; }

/* line 334, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee; }

/* line 337, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

/* line 342, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span.old {
  color: #777777; }

/* line 345, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

/* line 351, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px; }

/* line 355, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget.wider {
  width: 21em; }

/* line 358, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important; }

/* line 361, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.input-group.date .input-group-addon {
  cursor: pointer; }

/* line 364, /Users/constant/Repos/1010/resources/assets/admin/css/libs/04-datepicker.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*!
 * Bootstrap Colorpicker v2.5.1
 * https://itsjavi.com/bootstrap-colorpicker/
 *
 * Originally written by (c) 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0.txt
 *
 */
/* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-saturation {
  width: 100px;
  height: 100px;
  background-image: url("../../../images/admin/bootstrap-colorpicker/saturation.png");
  cursor: crosshair;
  float: left; }

/* line 17, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-saturation i {
  display: block;
  height: 5px;
  width: 5px;
  border: 1px solid #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  margin: -4px 0 0 -4px; }

/* line 30, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-saturation i b {
  display: block;
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

/* line 39, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-hue,
.colorpicker-alpha {
  width: 15px;
  height: 100px;
  float: left;
  cursor: row-resize;
  margin-left: 4px;
  margin-bottom: 4px; }

/* line 48, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-hue i,
.colorpicker-alpha i {
  display: block;
  height: 1px;
  background: #000;
  border-top: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: -1px; }

/* line 60, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-hue {
  background-image: url("../../../images/admin/bootstrap-colorpicker/hue.png"); }

/* line 63, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-alpha {
  background-image: url("../../../images/admin/bootstrap-colorpicker/alpha.png");
  display: none; }

/* line 67, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-saturation,
.colorpicker-hue,
.colorpicker-alpha {
  background-size: contain; }

/* line 72, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker {
  padding: 4px;
  min-width: 130px;
  margin-top: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 2500; }

/* line 81, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker:before,
.colorpicker:after {
  display: table;
  content: "";
  line-height: 0; }

/* line 87, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker:after {
  clear: both; }

/* line 90, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px; }

/* line 101, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 7px; }

/* line 111, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker div {
  position: relative; }

/* line 114, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-with-alpha {
  min-width: 140px; }

/* line 117, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-with-alpha .colorpicker-alpha {
  display: block; }

/* line 120, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-color {
  height: 10px;
  margin-top: 5px;
  clear: both;
  background-image: url("../../../images/admin/bootstrap-colorpicker/alpha.png");
  background-position: 0 100%; }

/* line 127, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-color div {
  height: 10px; }

/* line 130, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-selectors {
  display: none;
  height: 10px;
  margin-top: 5px;
  clear: both; }

/* line 136, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-selectors i {
  cursor: pointer;
  float: left;
  height: 10px;
  width: 10px; }

/* line 142, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-selectors i + i {
  margin-left: 3px; }

/* line 145, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-element .input-group-addon i,
.colorpicker-element .add-on i {
  display: inline-block;
  cursor: pointer;
  height: 16px;
  vertical-align: text-top;
  width: 16px; }

/* line 153, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-inline {
  position: relative;
  display: inline-block;
  float: none;
  z-index: auto; }

/* line 159, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal {
  width: 110px;
  min-width: 110px;
  height: auto; }

/* line 164, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-saturation {
  margin-bottom: 4px; }

/* line 167, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-color {
  width: 100px; }

/* line 170, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-hue,
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  width: 100px;
  height: 15px;
  float: left;
  cursor: col-resize;
  margin-left: 0px;
  margin-bottom: 4px; }

/* line 179, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-hue i,
.colorpicker.colorpicker-horizontal .colorpicker-alpha i {
  display: block;
  height: 15px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  border: none;
  margin-top: 0px; }

/* line 191, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-hue {
  background-image: url("../../../images/admin/bootstrap-colorpicker/hue-horizontal.png"); }

/* line 194, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  background-image: url("../../../images/admin/bootstrap-colorpicker/alpha-horizontal.png"); }

/* line 197, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-right:before {
  left: auto;
  right: 6px; }

/* line 201, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-right:after {
  left: auto;
  right: 7px; }

/* line 205, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-no-arrow:before {
  border-right: 0;
  border-left: 0; }

/* line 209, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-no-arrow:after {
  border-right: 0;
  border-left: 0; }

/* line 213, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-visible,
.colorpicker-alpha.colorpicker-visible,
.colorpicker-saturation.colorpicker-visible,
.colorpicker-hue.colorpicker-visible,
.colorpicker-selectors.colorpicker-visible {
  display: block; }

/* line 220, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker.colorpicker-hidden,
.colorpicker-alpha.colorpicker-hidden,
.colorpicker-saturation.colorpicker-hidden,
.colorpicker-hue.colorpicker-hidden,
.colorpicker-selectors.colorpicker-hidden {
  display: none; }

/* line 227, /Users/constant/Repos/1010/resources/assets/admin/css/libs/05-colorpicker.scss */
.colorpicker-inline.colorpicker-visible {
  display: inline-block; }

/*# sourceMappingURL=bootstrap-colorpicker.css.map */
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_core.scss */
.fa, .note-icon-bold, .note-icon-underline, .note-icon-eraser, .note-icon-unorderedlist, .note-icon-orderedlist, .note-icon-picture, .note-icon-link, .note-icon-chain-broken, .note-icon-arrows-alt, .note-icon-code, .note-icon-question, .note-icon-magic, .note-icon-caret, .note-icon-trash, .note-icon-italic, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
/* line 5, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_larger.scss */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

/* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_larger.scss */
.fa-2x {
  font-size: 2em; }

/* line 11, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_larger.scss */
.fa-3x {
  font-size: 3em; }

/* line 12, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_larger.scss */
.fa-4x {
  font-size: 4em; }

/* line 13, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_larger.scss */
.fa-5x {
  font-size: 5em; }

/* line 3, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_fixed-width.scss */
.fa-fw {
  width: 1.28571em;
  text-align: center; }

/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_list.scss */
.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none; }
  /* line 8, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_list.scss */
  .fa-ul > li {
    position: relative; }

/* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_list.scss */
.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center; }
  /* line 16, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_list.scss */
  .fa-li.fa-lg {
    left: -1.85714em; }

/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eee;
  border-radius: .1em; }

/* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.fa-pull-left {
  float: left; }

/* line 11, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.fa-pull-right {
  float: right; }

/* line 14, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.fa.fa-pull-left, .fa-pull-left.note-icon-bold, .fa-pull-left.note-icon-underline, .fa-pull-left.note-icon-eraser, .fa-pull-left.note-icon-unorderedlist, .fa-pull-left.note-icon-orderedlist, .fa-pull-left.note-icon-picture, .fa-pull-left.note-icon-link, .fa-pull-left.note-icon-chain-broken, .fa-pull-left.note-icon-arrows-alt, .fa-pull-left.note-icon-code, .fa-pull-left.note-icon-question, .fa-pull-left.note-icon-magic, .fa-pull-left.note-icon-caret, .fa-pull-left.note-icon-trash, .fa-pull-left.note-icon-italic, .select2-container--default .select2-selection--multiple .fa-pull-left.select2-selection__choice__remove {
  margin-right: .3em; }

/* line 15, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.fa.fa-pull-right, .fa-pull-right.note-icon-bold, .fa-pull-right.note-icon-underline, .fa-pull-right.note-icon-eraser, .fa-pull-right.note-icon-unorderedlist, .fa-pull-right.note-icon-orderedlist, .fa-pull-right.note-icon-picture, .fa-pull-right.note-icon-link, .fa-pull-right.note-icon-chain-broken, .fa-pull-right.note-icon-arrows-alt, .fa-pull-right.note-icon-code, .fa-pull-right.note-icon-question, .fa-pull-right.note-icon-magic, .fa-pull-right.note-icon-caret, .fa-pull-right.note-icon-trash, .fa-pull-right.note-icon-italic, .select2-container--default .select2-selection--multiple .fa-pull-right.select2-selection__choice__remove {
  margin-left: .3em; }

/* Deprecated as of 4.4.0 */
/* line 19, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.pull-right {
  float: right; }

/* line 20, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.pull-left {
  float: left; }

/* line 23, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.fa.pull-left, .pull-left.note-icon-bold, .pull-left.note-icon-underline, .pull-left.note-icon-eraser, .pull-left.note-icon-unorderedlist, .pull-left.note-icon-orderedlist, .pull-left.note-icon-picture, .pull-left.note-icon-link, .pull-left.note-icon-chain-broken, .pull-left.note-icon-arrows-alt, .pull-left.note-icon-code, .pull-left.note-icon-question, .pull-left.note-icon-magic, .pull-left.note-icon-caret, .pull-left.note-icon-trash, .pull-left.note-icon-italic, .select2-container--default .select2-selection--multiple .pull-left.select2-selection__choice__remove {
  margin-right: .3em; }

/* line 24, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_bordered-pulled.scss */
.fa.pull-right, .pull-right.note-icon-bold, .pull-right.note-icon-underline, .pull-right.note-icon-eraser, .pull-right.note-icon-unorderedlist, .pull-right.note-icon-orderedlist, .pull-right.note-icon-picture, .pull-right.note-icon-link, .pull-right.note-icon-chain-broken, .pull-right.note-icon-arrows-alt, .pull-right.note-icon-code, .pull-right.note-icon-question, .pull-right.note-icon-magic, .pull-right.note-icon-caret, .pull-right.note-icon-trash, .pull-right.note-icon-italic, .select2-container--default .select2-selection--multiple .pull-right.select2-selection__choice__remove {
  margin-left: .3em; }

/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_animated.scss */
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

/* line 9, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_animated.scss */
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_rotated-flipped.scss */
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

/* line 5, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_rotated-flipped.scss */
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

/* line 6, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_rotated-flipped.scss */
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

/* line 8, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_rotated-flipped.scss */
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

/* line 9, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_rotated-flipped.scss */
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

/* line 14, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_rotated-flipped.scss */
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_stacked.scss */
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

/* line 12, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_stacked.scss */
.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

/* line 18, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_stacked.scss */
.fa-stack-1x {
  line-height: inherit; }

/* line 19, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_stacked.scss */
.fa-stack-2x {
  font-size: 2em; }

/* line 20, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_stacked.scss */
.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-glass:before {
  content: ""; }

/* line 5, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-music:before {
  content: ""; }

/* line 6, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-search:before {
  content: ""; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-envelope-o:before {
  content: ""; }

/* line 8, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-heart:before {
  content: ""; }

/* line 9, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-star:before {
  content: ""; }

/* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-star-o:before {
  content: ""; }

/* line 11, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user:before {
  content: ""; }

/* line 12, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-film:before {
  content: ""; }

/* line 13, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-th-large:before {
  content: ""; }

/* line 14, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-th:before {
  content: ""; }

/* line 15, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-th-list:before {
  content: ""; }

/* line 16, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-check:before {
  content: ""; }

/* line 17, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-remove:before,
.fa-close:before,
.fa-times:before,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
  content: ""; }

/* line 18, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-search-plus:before {
  content: ""; }

/* line 19, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-search-minus:before {
  content: ""; }

/* line 20, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-power-off:before {
  content: ""; }

/* line 21, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-signal:before {
  content: ""; }

/* line 22, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gear:before,
.fa-cog:before {
  content: ""; }

/* line 23, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-trash-o:before, .note-icon-trash:before {
  content: ""; }

/* line 24, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-home:before {
  content: ""; }

/* line 25, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-o:before {
  content: ""; }

/* line 26, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-clock-o:before {
  content: ""; }

/* line 27, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-road:before {
  content: ""; }

/* line 28, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-download:before {
  content: ""; }

/* line 29, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-down:before {
  content: ""; }

/* line 30, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-up:before {
  content: ""; }

/* line 31, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-inbox:before {
  content: ""; }

/* line 32, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-play-circle-o:before {
  content: ""; }

/* line 33, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

/* line 34, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-refresh:before {
  content: ""; }

/* line 35, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-list-alt:before {
  content: ""; }

/* line 36, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-lock:before {
  content: ""; }

/* line 37, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-flag:before {
  content: ""; }

/* line 38, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-headphones:before {
  content: ""; }

/* line 39, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-volume-off:before {
  content: ""; }

/* line 40, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-volume-down:before {
  content: ""; }

/* line 41, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-volume-up:before {
  content: ""; }

/* line 42, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-qrcode:before {
  content: ""; }

/* line 43, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-barcode:before {
  content: ""; }

/* line 44, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tag:before {
  content: ""; }

/* line 45, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tags:before {
  content: ""; }

/* line 46, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-book:before {
  content: ""; }

/* line 47, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bookmark:before {
  content: ""; }

/* line 48, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-print:before {
  content: ""; }

/* line 49, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-camera:before {
  content: ""; }

/* line 50, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-font:before {
  content: ""; }

/* line 51, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bold:before, .note-icon-bold:before {
  content: ""; }

/* line 52, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-italic:before, .note-icon-italic:before {
  content: ""; }

/* line 53, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-text-height:before {
  content: ""; }

/* line 54, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-text-width:before {
  content: ""; }

/* line 55, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-align-left:before {
  content: ""; }

/* line 56, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-align-center:before {
  content: ""; }

/* line 57, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-align-right:before {
  content: ""; }

/* line 58, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-align-justify:before {
  content: ""; }

/* line 59, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-list:before {
  content: ""; }

/* line 60, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

/* line 61, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-indent:before {
  content: ""; }

/* line 62, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-video-camera:before {
  content: ""; }

/* line 63, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-photo:before,
.fa-image:before,
.note-icon-picture:before,
.fa-picture-o:before {
  content: ""; }

/* line 64, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pencil:before {
  content: ""; }

/* line 65, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-map-marker:before {
  content: ""; }

/* line 66, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-adjust:before {
  content: ""; }

/* line 67, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tint:before {
  content: ""; }

/* line 68, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

/* line 69, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-share-square-o:before {
  content: ""; }

/* line 70, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-check-square-o:before {
  content: ""; }

/* line 71, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrows:before {
  content: ""; }

/* line 72, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-step-backward:before {
  content: ""; }

/* line 73, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fast-backward:before {
  content: ""; }

/* line 74, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-backward:before {
  content: ""; }

/* line 75, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-play:before {
  content: ""; }

/* line 76, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pause:before {
  content: ""; }

/* line 77, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stop:before {
  content: ""; }

/* line 78, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-forward:before {
  content: ""; }

/* line 79, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fast-forward:before {
  content: ""; }

/* line 80, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-step-forward:before {
  content: ""; }

/* line 81, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-eject:before {
  content: ""; }

/* line 82, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-left:before {
  content: ""; }

/* line 83, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-right:before {
  content: ""; }

/* line 84, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-plus-circle:before {
  content: ""; }

/* line 85, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-minus-circle:before {
  content: ""; }

/* line 86, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-times-circle:before {
  content: ""; }

/* line 87, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-check-circle:before {
  content: ""; }

/* line 88, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-question-circle:before {
  content: ""; }

/* line 89, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-info-circle:before {
  content: ""; }

/* line 90, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-crosshairs:before {
  content: ""; }

/* line 91, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-times-circle-o:before {
  content: ""; }

/* line 92, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-check-circle-o:before {
  content: ""; }

/* line 93, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ban:before {
  content: ""; }

/* line 94, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-left:before {
  content: ""; }

/* line 95, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-right:before {
  content: ""; }

/* line 96, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-up:before {
  content: ""; }

/* line 97, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-down:before {
  content: ""; }

/* line 98, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

/* line 99, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-expand:before, .note-icon-arrows-alt:before {
  content: ""; }

/* line 100, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-compress:before {
  content: ""; }

/* line 101, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-plus:before {
  content: ""; }

/* line 102, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-minus:before {
  content: ""; }

/* line 103, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-asterisk:before {
  content: ""; }

/* line 104, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-exclamation-circle:before {
  content: ""; }

/* line 105, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gift:before {
  content: ""; }

/* line 106, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-leaf:before {
  content: ""; }

/* line 107, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fire:before {
  content: ""; }

/* line 108, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-eye:before {
  content: ""; }

/* line 109, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-eye-slash:before {
  content: ""; }

/* line 110, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

/* line 111, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-plane:before {
  content: ""; }

/* line 112, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-calendar:before {
  content: ""; }

/* line 113, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-random:before {
  content: ""; }

/* line 114, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-comment:before {
  content: ""; }

/* line 115, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-magnet:before {
  content: ""; }

/* line 116, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-up:before {
  content: ""; }

/* line 117, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-down:before {
  content: ""; }

/* line 118, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-retweet:before {
  content: ""; }

/* line 119, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-shopping-cart:before {
  content: ""; }

/* line 120, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-folder:before {
  content: ""; }

/* line 121, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-folder-open:before {
  content: ""; }

/* line 122, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrows-v:before {
  content: ""; }

/* line 123, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrows-h:before {
  content: ""; }

/* line 124, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

/* line 125, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-twitter-square:before {
  content: ""; }

/* line 126, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-facebook-square:before {
  content: ""; }

/* line 127, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-camera-retro:before {
  content: ""; }

/* line 128, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-key:before {
  content: ""; }

/* line 129, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gears:before,
.fa-cogs:before {
  content: ""; }

/* line 130, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-comments:before {
  content: ""; }

/* line 131, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thumbs-o-up:before {
  content: ""; }

/* line 132, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thumbs-o-down:before {
  content: ""; }

/* line 133, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-star-half:before {
  content: ""; }

/* line 134, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-heart-o:before {
  content: ""; }

/* line 135, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sign-out:before {
  content: ""; }

/* line 136, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-linkedin-square:before {
  content: ""; }

/* line 137, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thumb-tack:before {
  content: ""; }

/* line 138, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-external-link:before {
  content: ""; }

/* line 139, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sign-in:before {
  content: ""; }

/* line 140, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-trophy:before {
  content: ""; }

/* line 141, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-github-square:before {
  content: ""; }

/* line 142, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-upload:before {
  content: ""; }

/* line 143, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-lemon-o:before {
  content: ""; }

/* line 144, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-phone:before {
  content: ""; }

/* line 145, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-square-o:before {
  content: ""; }

/* line 146, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bookmark-o:before {
  content: ""; }

/* line 147, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-phone-square:before {
  content: ""; }

/* line 148, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-twitter:before {
  content: ""; }

/* line 149, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

/* line 150, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-github:before {
  content: ""; }

/* line 151, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-unlock:before {
  content: ""; }

/* line 152, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-credit-card:before {
  content: ""; }

/* line 153, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-feed:before,
.fa-rss:before {
  content: ""; }

/* line 154, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hdd-o:before {
  content: ""; }

/* line 155, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bullhorn:before {
  content: ""; }

/* line 156, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bell:before {
  content: ""; }

/* line 157, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-certificate:before {
  content: ""; }

/* line 158, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-o-right:before {
  content: ""; }

/* line 159, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-o-left:before {
  content: ""; }

/* line 160, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-o-up:before {
  content: ""; }

/* line 161, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-o-down:before {
  content: ""; }

/* line 162, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-left:before {
  content: ""; }

/* line 163, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-right:before {
  content: ""; }

/* line 164, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-up:before {
  content: ""; }

/* line 165, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-down:before {
  content: ""; }

/* line 166, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-globe:before {
  content: ""; }

/* line 167, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wrench:before {
  content: ""; }

/* line 168, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tasks:before {
  content: ""; }

/* line 169, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-filter:before {
  content: ""; }

/* line 170, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-briefcase:before {
  content: ""; }

/* line 171, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrows-alt:before {
  content: ""; }

/* line 172, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-group:before,
.fa-users:before {
  content: ""; }

/* line 173, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chain:before,
.fa-link:before,
.note-icon-link:before {
  content: ""; }

/* line 174, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cloud:before {
  content: ""; }

/* line 175, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-flask:before {
  content: ""; }

/* line 176, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cut:before,
.fa-scissors:before {
  content: ""; }

/* line 177, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-copy:before,
.fa-files-o:before {
  content: ""; }

/* line 178, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-paperclip:before {
  content: ""; }

/* line 179, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

/* line 180, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-square:before {
  content: ""; }

/* line 181, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

/* line 182, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-list-ul:before, .note-icon-unorderedlist:before {
  content: ""; }

/* line 183, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-list-ol:before, .note-icon-orderedlist:before {
  content: ""; }

/* line 184, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-strikethrough:before {
  content: ""; }

/* line 185, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-underline:before, .note-icon-underline:before {
  content: ""; }

/* line 186, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-table:before {
  content: ""; }

/* line 187, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-magic:before {
  content: ""; }

/* line 188, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-truck:before {
  content: ""; }

/* line 189, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pinterest:before {
  content: ""; }

/* line 190, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pinterest-square:before {
  content: ""; }

/* line 191, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-google-plus-square:before {
  content: ""; }

/* line 192, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-google-plus:before {
  content: ""; }

/* line 193, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-money:before {
  content: ""; }

/* line 194, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-caret-down:before, .note-icon-caret:before {
  content: ""; }

/* line 195, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-caret-up:before {
  content: ""; }

/* line 196, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-caret-left:before {
  content: ""; }

/* line 197, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-caret-right:before {
  content: ""; }

/* line 198, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-columns:before {
  content: ""; }

/* line 199, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

/* line 200, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

/* line 201, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

/* line 202, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-envelope:before {
  content: ""; }

/* line 203, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-linkedin:before {
  content: ""; }

/* line 204, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

/* line 205, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-legal:before,
.fa-gavel:before {
  content: ""; }

/* line 206, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

/* line 207, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-comment-o:before {
  content: ""; }

/* line 208, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-comments-o:before {
  content: ""; }

/* line 209, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-flash:before,
.fa-bolt:before {
  content: ""; }

/* line 210, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sitemap:before {
  content: ""; }

/* line 211, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-umbrella:before {
  content: ""; }

/* line 212, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

/* line 213, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-lightbulb-o:before {
  content: ""; }

/* line 214, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-exchange:before {
  content: ""; }

/* line 215, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cloud-download:before {
  content: ""; }

/* line 216, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cloud-upload:before {
  content: ""; }

/* line 217, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user-md:before {
  content: ""; }

/* line 218, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stethoscope:before {
  content: ""; }

/* line 219, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-suitcase:before {
  content: ""; }

/* line 220, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bell-o:before {
  content: ""; }

/* line 221, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-coffee:before {
  content: ""; }

/* line 222, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cutlery:before {
  content: ""; }

/* line 223, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-text-o:before {
  content: ""; }

/* line 224, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-building-o:before {
  content: ""; }

/* line 225, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hospital-o:before {
  content: ""; }

/* line 226, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ambulance:before {
  content: ""; }

/* line 227, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-medkit:before {
  content: ""; }

/* line 228, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fighter-jet:before {
  content: ""; }

/* line 229, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-beer:before {
  content: ""; }

/* line 230, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-h-square:before {
  content: ""; }

/* line 231, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-plus-square:before {
  content: ""; }

/* line 232, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-double-left:before {
  content: ""; }

/* line 233, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-double-right:before {
  content: ""; }

/* line 234, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-double-up:before {
  content: ""; }

/* line 235, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-double-down:before {
  content: ""; }

/* line 236, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-left:before {
  content: ""; }

/* line 237, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-right:before {
  content: ""; }

/* line 238, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-up:before {
  content: ""; }

/* line 239, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angle-down:before {
  content: ""; }

/* line 240, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-desktop:before {
  content: ""; }

/* line 241, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-laptop:before {
  content: ""; }

/* line 242, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tablet:before {
  content: ""; }

/* line 243, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

/* line 244, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-circle-o:before {
  content: ""; }

/* line 245, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-quote-left:before {
  content: ""; }

/* line 246, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-quote-right:before {
  content: ""; }

/* line 247, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-spinner:before {
  content: ""; }

/* line 248, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-circle:before {
  content: ""; }

/* line 249, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

/* line 250, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-github-alt:before {
  content: ""; }

/* line 251, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-folder-o:before {
  content: ""; }

/* line 252, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-folder-open-o:before {
  content: ""; }

/* line 253, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-smile-o:before {
  content: ""; }

/* line 254, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-frown-o:before {
  content: ""; }

/* line 255, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-meh-o:before {
  content: ""; }

/* line 256, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gamepad:before {
  content: ""; }

/* line 257, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-keyboard-o:before {
  content: ""; }

/* line 258, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-flag-o:before {
  content: ""; }

/* line 259, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-flag-checkered:before {
  content: ""; }

/* line 260, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-terminal:before {
  content: ""; }

/* line 261, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-code:before, .note-icon-code:before {
  content: ""; }

/* line 262, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

/* line 263, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

/* line 264, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-location-arrow:before {
  content: ""; }

/* line 265, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-crop:before {
  content: ""; }

/* line 266, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-code-fork:before {
  content: ""; }

/* line 267, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-unlink:before, .note-icon-chain-broken:before,
.fa-chain-broken:before {
  content: ""; }

/* line 268, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-question:before, .note-icon-question:before {
  content: ""; }

/* line 269, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-info:before {
  content: ""; }

/* line 270, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-exclamation:before {
  content: ""; }

/* line 271, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-superscript:before {
  content: ""; }

/* line 272, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-subscript:before {
  content: ""; }

/* line 273, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-eraser:before, .note-icon-eraser:before {
  content: ""; }

/* line 274, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-puzzle-piece:before {
  content: ""; }

/* line 275, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-microphone:before {
  content: ""; }

/* line 276, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-microphone-slash:before {
  content: ""; }

/* line 277, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-shield:before {
  content: ""; }

/* line 278, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-calendar-o:before {
  content: ""; }

/* line 279, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fire-extinguisher:before {
  content: ""; }

/* line 280, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-rocket:before {
  content: ""; }

/* line 281, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-maxcdn:before {
  content: ""; }

/* line 282, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-circle-left:before {
  content: ""; }

/* line 283, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-circle-right:before {
  content: ""; }

/* line 284, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-circle-up:before {
  content: ""; }

/* line 285, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chevron-circle-down:before {
  content: ""; }

/* line 286, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-html5:before {
  content: ""; }

/* line 287, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-css3:before {
  content: ""; }

/* line 288, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-anchor:before {
  content: ""; }

/* line 289, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-unlock-alt:before {
  content: ""; }

/* line 290, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bullseye:before {
  content: ""; }

/* line 291, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ellipsis-h:before, .media-panel .list-group-item a:hover i.fa-image:before, .media-panel .list-group-item a:hover i.note-icon-picture:before {
  content: ""; }

/* line 292, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ellipsis-v:before {
  content: ""; }

/* line 293, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-rss-square:before {
  content: ""; }

/* line 294, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-play-circle:before {
  content: ""; }

/* line 295, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ticket:before {
  content: ""; }

/* line 296, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-minus-square:before {
  content: ""; }

/* line 297, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-minus-square-o:before {
  content: ""; }

/* line 298, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-level-up:before {
  content: ""; }

/* line 299, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-level-down:before {
  content: ""; }

/* line 300, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-check-square:before {
  content: ""; }

/* line 301, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pencil-square:before {
  content: ""; }

/* line 302, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-external-link-square:before {
  content: ""; }

/* line 303, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-share-square:before {
  content: ""; }

/* line 304, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-compass:before {
  content: ""; }

/* line 305, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

/* line 306, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

/* line 307, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

/* line 308, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-euro:before,
.fa-eur:before {
  content: ""; }

/* line 309, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gbp:before {
  content: ""; }

/* line 310, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-dollar:before,
.fa-usd:before {
  content: ""; }

/* line 311, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-rupee:before,
.fa-inr:before {
  content: ""; }

/* line 312, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

/* line 313, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

/* line 314, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-won:before,
.fa-krw:before {
  content: ""; }

/* line 315, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

/* line 316, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file:before {
  content: ""; }

/* line 317, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-text:before {
  content: ""; }

/* line 318, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-alpha-asc:before {
  content: ""; }

/* line 319, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-alpha-desc:before {
  content: ""; }

/* line 320, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-amount-asc:before {
  content: ""; }

/* line 321, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-amount-desc:before {
  content: ""; }

/* line 322, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-numeric-asc:before {
  content: ""; }

/* line 323, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sort-numeric-desc:before {
  content: ""; }

/* line 324, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thumbs-up:before {
  content: ""; }

/* line 325, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thumbs-down:before {
  content: ""; }

/* line 326, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-youtube-square:before {
  content: ""; }

/* line 327, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-youtube:before {
  content: ""; }

/* line 328, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-xing:before {
  content: ""; }

/* line 329, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-xing-square:before {
  content: ""; }

/* line 330, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-youtube-play:before {
  content: ""; }

/* line 331, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-dropbox:before {
  content: ""; }

/* line 332, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stack-overflow:before {
  content: ""; }

/* line 333, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-instagram:before {
  content: ""; }

/* line 334, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-flickr:before {
  content: ""; }

/* line 335, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-adn:before {
  content: ""; }

/* line 336, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bitbucket:before {
  content: ""; }

/* line 337, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bitbucket-square:before {
  content: ""; }

/* line 338, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tumblr:before {
  content: ""; }

/* line 339, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tumblr-square:before {
  content: ""; }

/* line 340, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-long-arrow-down:before {
  content: ""; }

/* line 341, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-long-arrow-up:before {
  content: ""; }

/* line 342, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-long-arrow-left:before {
  content: ""; }

/* line 343, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-long-arrow-right:before {
  content: ""; }

/* line 344, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-apple:before {
  content: ""; }

/* line 345, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-windows:before {
  content: ""; }

/* line 346, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-android:before {
  content: ""; }

/* line 347, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-linux:before {
  content: ""; }

/* line 348, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-dribbble:before {
  content: ""; }

/* line 349, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-skype:before {
  content: ""; }

/* line 350, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-foursquare:before {
  content: ""; }

/* line 351, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-trello:before {
  content: ""; }

/* line 352, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-female:before {
  content: ""; }

/* line 353, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-male:before {
  content: ""; }

/* line 354, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

/* line 355, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sun-o:before {
  content: ""; }

/* line 356, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-moon-o:before {
  content: ""; }

/* line 357, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-archive:before {
  content: ""; }

/* line 358, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bug:before {
  content: ""; }

/* line 359, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-vk:before {
  content: ""; }

/* line 360, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-weibo:before {
  content: ""; }

/* line 361, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-renren:before {
  content: ""; }

/* line 362, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pagelines:before {
  content: ""; }

/* line 363, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stack-exchange:before {
  content: ""; }

/* line 364, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-right:before {
  content: ""; }

/* line 365, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-left:before {
  content: ""; }

/* line 366, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

/* line 367, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-dot-circle-o:before {
  content: ""; }

/* line 368, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wheelchair:before {
  content: ""; }

/* line 369, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-vimeo-square:before {
  content: ""; }

/* line 370, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

/* line 371, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-plus-square-o:before {
  content: ""; }

/* line 372, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-space-shuttle:before {
  content: ""; }

/* line 373, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-slack:before {
  content: ""; }

/* line 374, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-envelope-square:before {
  content: ""; }

/* line 375, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wordpress:before {
  content: ""; }

/* line 376, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-openid:before {
  content: ""; }

/* line 377, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

/* line 378, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

/* line 379, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-yahoo:before {
  content: ""; }

/* line 380, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-google:before {
  content: ""; }

/* line 381, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-reddit:before {
  content: ""; }

/* line 382, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-reddit-square:before {
  content: ""; }

/* line 383, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stumbleupon-circle:before {
  content: ""; }

/* line 384, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stumbleupon:before {
  content: ""; }

/* line 385, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-delicious:before {
  content: ""; }

/* line 386, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-digg:before {
  content: ""; }

/* line 387, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pied-piper-pp:before {
  content: ""; }

/* line 388, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pied-piper-alt:before {
  content: ""; }

/* line 389, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-drupal:before {
  content: ""; }

/* line 390, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-joomla:before {
  content: ""; }

/* line 391, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-language:before {
  content: ""; }

/* line 392, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fax:before {
  content: ""; }

/* line 393, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-building:before {
  content: ""; }

/* line 394, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-child:before {
  content: ""; }

/* line 395, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-paw:before {
  content: ""; }

/* line 396, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-spoon:before {
  content: ""; }

/* line 397, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cube:before {
  content: ""; }

/* line 398, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cubes:before {
  content: ""; }

/* line 399, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-behance:before {
  content: ""; }

/* line 400, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-behance-square:before {
  content: ""; }

/* line 401, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-steam:before {
  content: ""; }

/* line 402, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-steam-square:before {
  content: ""; }

/* line 403, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-recycle:before {
  content: ""; }

/* line 404, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-automobile:before,
.fa-car:before {
  content: ""; }

/* line 405, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cab:before,
.fa-taxi:before {
  content: ""; }

/* line 406, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tree:before {
  content: ""; }

/* line 407, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-spotify:before {
  content: ""; }

/* line 408, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-deviantart:before {
  content: ""; }

/* line 409, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-soundcloud:before {
  content: ""; }

/* line 410, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-database:before {
  content: ""; }

/* line 411, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-pdf-o:before {
  content: ""; }

/* line 412, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-word-o:before {
  content: ""; }

/* line 413, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-excel-o:before {
  content: ""; }

/* line 414, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-powerpoint-o:before {
  content: ""; }

/* line 415, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

/* line 416, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

/* line 417, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

/* line 418, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

/* line 419, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-file-code-o:before {
  content: ""; }

/* line 420, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-vine:before {
  content: ""; }

/* line 421, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-codepen:before {
  content: ""; }

/* line 422, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-jsfiddle:before {
  content: ""; }

/* line 423, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

/* line 424, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-circle-o-notch:before {
  content: ""; }

/* line 425, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: ""; }

/* line 426, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ge:before,
.fa-empire:before {
  content: ""; }

/* line 427, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-git-square:before {
  content: ""; }

/* line 428, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-git:before {
  content: ""; }

/* line 429, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

/* line 430, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tencent-weibo:before {
  content: ""; }

/* line 431, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-qq:before {
  content: ""; }

/* line 432, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

/* line 433, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

/* line 434, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

/* line 435, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-history:before {
  content: ""; }

/* line 436, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-circle-thin:before {
  content: ""; }

/* line 437, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-header:before, .note-icon-magic:before {
  content: ""; }

/* line 438, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-paragraph:before {
  content: ""; }

/* line 439, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sliders:before {
  content: ""; }

/* line 440, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-share-alt:before {
  content: ""; }

/* line 441, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-share-alt-square:before {
  content: ""; }

/* line 442, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bomb:before {
  content: ""; }

/* line 443, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

/* line 444, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tty:before {
  content: ""; }

/* line 445, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-binoculars:before {
  content: ""; }

/* line 446, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-plug:before {
  content: ""; }

/* line 447, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-slideshare:before {
  content: ""; }

/* line 448, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-twitch:before {
  content: ""; }

/* line 449, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-yelp:before {
  content: ""; }

/* line 450, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-newspaper-o:before {
  content: ""; }

/* line 451, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wifi:before {
  content: ""; }

/* line 452, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-calculator:before {
  content: ""; }

/* line 453, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-paypal:before {
  content: ""; }

/* line 454, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-google-wallet:before {
  content: ""; }

/* line 455, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-visa:before {
  content: ""; }

/* line 456, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-mastercard:before {
  content: ""; }

/* line 457, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-discover:before {
  content: ""; }

/* line 458, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-amex:before {
  content: ""; }

/* line 459, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-paypal:before {
  content: ""; }

/* line 460, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-stripe:before {
  content: ""; }

/* line 461, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bell-slash:before {
  content: ""; }

/* line 462, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bell-slash-o:before {
  content: ""; }

/* line 463, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-trash:before {
  content: ""; }

/* line 464, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-copyright:before {
  content: ""; }

/* line 465, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-at:before {
  content: ""; }

/* line 466, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-eyedropper:before {
  content: ""; }

/* line 467, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-paint-brush:before {
  content: ""; }

/* line 468, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-birthday-cake:before {
  content: ""; }

/* line 469, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-area-chart:before {
  content: ""; }

/* line 470, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pie-chart:before {
  content: ""; }

/* line 471, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-line-chart:before {
  content: ""; }

/* line 472, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-lastfm:before {
  content: ""; }

/* line 473, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-lastfm-square:before {
  content: ""; }

/* line 474, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-toggle-off:before {
  content: ""; }

/* line 475, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-toggle-on:before {
  content: ""; }

/* line 476, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bicycle:before {
  content: ""; }

/* line 477, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bus:before {
  content: ""; }

/* line 478, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ioxhost:before {
  content: ""; }

/* line 479, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-angellist:before {
  content: ""; }

/* line 480, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc:before {
  content: ""; }

/* line 481, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

/* line 482, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-meanpath:before {
  content: ""; }

/* line 483, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-buysellads:before {
  content: ""; }

/* line 484, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-connectdevelop:before {
  content: ""; }

/* line 485, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-dashcube:before {
  content: ""; }

/* line 486, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-forumbee:before {
  content: ""; }

/* line 487, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-leanpub:before {
  content: ""; }

/* line 488, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sellsy:before {
  content: ""; }

/* line 489, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-shirtsinbulk:before {
  content: ""; }

/* line 490, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-simplybuilt:before {
  content: ""; }

/* line 491, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-skyatlas:before {
  content: ""; }

/* line 492, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cart-plus:before {
  content: ""; }

/* line 493, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cart-arrow-down:before {
  content: ""; }

/* line 494, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-diamond:before {
  content: ""; }

/* line 495, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ship:before {
  content: ""; }

/* line 496, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user-secret:before {
  content: ""; }

/* line 497, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-motorcycle:before {
  content: ""; }

/* line 498, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-street-view:before {
  content: ""; }

/* line 499, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-heartbeat:before {
  content: ""; }

/* line 500, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-venus:before {
  content: ""; }

/* line 501, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mars:before {
  content: ""; }

/* line 502, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mercury:before {
  content: ""; }

/* line 503, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

/* line 504, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-transgender-alt:before {
  content: ""; }

/* line 505, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-venus-double:before {
  content: ""; }

/* line 506, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mars-double:before {
  content: ""; }

/* line 507, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-venus-mars:before {
  content: ""; }

/* line 508, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mars-stroke:before {
  content: ""; }

/* line 509, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mars-stroke-v:before {
  content: ""; }

/* line 510, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mars-stroke-h:before {
  content: ""; }

/* line 511, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-neuter:before {
  content: ""; }

/* line 512, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-genderless:before {
  content: ""; }

/* line 513, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-facebook-official:before {
  content: ""; }

/* line 514, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pinterest-p:before {
  content: ""; }

/* line 515, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-whatsapp:before {
  content: ""; }

/* line 516, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-server:before {
  content: ""; }

/* line 517, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user-plus:before {
  content: ""; }

/* line 518, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user-times:before {
  content: ""; }

/* line 519, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hotel:before,
.fa-bed:before {
  content: ""; }

/* line 520, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-viacoin:before {
  content: ""; }

/* line 521, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-train:before {
  content: ""; }

/* line 522, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-subway:before {
  content: ""; }

/* line 523, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-medium:before {
  content: ""; }

/* line 524, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

/* line 525, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-optin-monster:before {
  content: ""; }

/* line 526, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-opencart:before {
  content: ""; }

/* line 527, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-expeditedssl:before {
  content: ""; }

/* line 528, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: ""; }

/* line 529, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

/* line 530, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

/* line 531, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

/* line 532, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

/* line 533, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mouse-pointer:before {
  content: ""; }

/* line 534, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-i-cursor:before {
  content: ""; }

/* line 535, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-object-group:before {
  content: ""; }

/* line 536, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-object-ungroup:before {
  content: ""; }

/* line 537, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sticky-note:before {
  content: ""; }

/* line 538, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-sticky-note-o:before {
  content: ""; }

/* line 539, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-jcb:before {
  content: ""; }

/* line 540, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-cc-diners-club:before {
  content: ""; }

/* line 541, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-clone:before {
  content: ""; }

/* line 542, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-balance-scale:before {
  content: ""; }

/* line 543, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hourglass-o:before {
  content: ""; }

/* line 544, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

/* line 545, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

/* line 546, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

/* line 547, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hourglass:before {
  content: ""; }

/* line 548, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

/* line 549, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

/* line 550, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-scissors-o:before {
  content: ""; }

/* line 551, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-lizard-o:before {
  content: ""; }

/* line 552, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-spock-o:before {
  content: ""; }

/* line 553, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-pointer-o:before {
  content: ""; }

/* line 554, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hand-peace-o:before {
  content: ""; }

/* line 555, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-trademark:before {
  content: ""; }

/* line 556, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-registered:before {
  content: ""; }

/* line 557, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-creative-commons:before {
  content: ""; }

/* line 558, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gg:before {
  content: ""; }

/* line 559, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gg-circle:before {
  content: ""; }

/* line 560, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tripadvisor:before {
  content: ""; }

/* line 561, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-odnoklassniki:before {
  content: ""; }

/* line 562, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-odnoklassniki-square:before {
  content: ""; }

/* line 563, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-get-pocket:before {
  content: ""; }

/* line 564, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wikipedia-w:before {
  content: ""; }

/* line 565, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-safari:before {
  content: ""; }

/* line 566, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-chrome:before {
  content: ""; }

/* line 567, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-firefox:before {
  content: ""; }

/* line 568, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-opera:before {
  content: ""; }

/* line 569, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-internet-explorer:before {
  content: ""; }

/* line 570, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-tv:before,
.fa-television:before {
  content: ""; }

/* line 571, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-contao:before {
  content: ""; }

/* line 572, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-500px:before {
  content: ""; }

/* line 573, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-amazon:before {
  content: ""; }

/* line 574, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-calendar-plus-o:before {
  content: ""; }

/* line 575, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-calendar-minus-o:before {
  content: ""; }

/* line 576, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-calendar-times-o:before {
  content: ""; }

/* line 577, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-calendar-check-o:before {
  content: ""; }

/* line 578, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-industry:before {
  content: ""; }

/* line 579, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-map-pin:before {
  content: ""; }

/* line 580, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-map-signs:before {
  content: ""; }

/* line 581, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-map-o:before {
  content: ""; }

/* line 582, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-map:before {
  content: ""; }

/* line 583, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-commenting:before {
  content: ""; }

/* line 584, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-commenting-o:before {
  content: ""; }

/* line 585, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-houzz:before {
  content: ""; }

/* line 586, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-vimeo:before {
  content: ""; }

/* line 587, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-black-tie:before {
  content: ""; }

/* line 588, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fonticons:before {
  content: ""; }

/* line 589, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-reddit-alien:before {
  content: ""; }

/* line 590, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-edge:before {
  content: ""; }

/* line 591, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-credit-card-alt:before {
  content: ""; }

/* line 592, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-codiepie:before {
  content: ""; }

/* line 593, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-modx:before {
  content: ""; }

/* line 594, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fort-awesome:before {
  content: ""; }

/* line 595, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-usb:before {
  content: ""; }

/* line 596, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-product-hunt:before {
  content: ""; }

/* line 597, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-mixcloud:before {
  content: ""; }

/* line 598, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-scribd:before {
  content: ""; }

/* line 599, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pause-circle:before {
  content: ""; }

/* line 600, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pause-circle-o:before {
  content: ""; }

/* line 601, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stop-circle:before {
  content: ""; }

/* line 602, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-stop-circle-o:before {
  content: ""; }

/* line 603, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-shopping-bag:before {
  content: ""; }

/* line 604, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-shopping-basket:before {
  content: ""; }

/* line 605, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-hashtag:before {
  content: ""; }

/* line 606, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bluetooth:before {
  content: ""; }

/* line 607, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bluetooth-b:before {
  content: ""; }

/* line 608, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-percent:before {
  content: ""; }

/* line 609, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-gitlab:before {
  content: ""; }

/* line 610, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wpbeginner:before {
  content: ""; }

/* line 611, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wpforms:before {
  content: ""; }

/* line 612, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-envira:before {
  content: ""; }

/* line 613, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-universal-access:before {
  content: ""; }

/* line 614, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wheelchair-alt:before {
  content: ""; }

/* line 615, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-question-circle-o:before {
  content: ""; }

/* line 616, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-blind:before {
  content: ""; }

/* line 617, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-audio-description:before {
  content: ""; }

/* line 618, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-volume-control-phone:before {
  content: ""; }

/* line 619, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-braille:before {
  content: ""; }

/* line 620, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-assistive-listening-systems:before {
  content: ""; }

/* line 621, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: ""; }

/* line 622, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: ""; }

/* line 623, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-glide:before {
  content: ""; }

/* line 624, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-glide-g:before {
  content: ""; }

/* line 625, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-signing:before,
.fa-sign-language:before {
  content: ""; }

/* line 626, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-low-vision:before {
  content: ""; }

/* line 627, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-viadeo:before {
  content: ""; }

/* line 628, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-viadeo-square:before {
  content: ""; }

/* line 629, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-snapchat:before {
  content: ""; }

/* line 630, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-snapchat-ghost:before {
  content: ""; }

/* line 631, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-snapchat-square:before {
  content: ""; }

/* line 632, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-pied-piper:before {
  content: ""; }

/* line 633, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-first-order:before {
  content: ""; }

/* line 634, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-yoast:before {
  content: ""; }

/* line 635, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-themeisle:before {
  content: ""; }

/* line 636, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: ""; }

/* line 637, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-fa:before,
.fa-font-awesome:before {
  content: ""; }

/* line 638, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-handshake-o:before {
  content: ""; }

/* line 639, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-envelope-open:before {
  content: ""; }

/* line 640, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-envelope-open-o:before {
  content: ""; }

/* line 641, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-linode:before {
  content: ""; }

/* line 642, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-address-book:before {
  content: ""; }

/* line 643, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-address-book-o:before {
  content: ""; }

/* line 644, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-vcard:before,
.fa-address-card:before {
  content: ""; }

/* line 645, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-vcard-o:before,
.fa-address-card-o:before {
  content: ""; }

/* line 646, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user-circle:before {
  content: ""; }

/* line 647, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user-circle-o:before {
  content: ""; }

/* line 648, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-user-o:before {
  content: ""; }

/* line 649, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-id-badge:before {
  content: ""; }

/* line 650, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-drivers-license:before,
.fa-id-card:before {
  content: ""; }

/* line 651, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: ""; }

/* line 652, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-quora:before {
  content: ""; }

/* line 653, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-free-code-camp:before {
  content: ""; }

/* line 654, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-telegram:before {
  content: ""; }

/* line 655, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: ""; }

/* line 656, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: ""; }

/* line 657, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: ""; }

/* line 658, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: ""; }

/* line 659, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: ""; }

/* line 660, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-shower:before {
  content: ""; }

/* line 661, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: ""; }

/* line 662, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-podcast:before {
  content: ""; }

/* line 663, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-window-maximize:before {
  content: ""; }

/* line 664, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-window-minimize:before {
  content: ""; }

/* line 665, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-window-restore:before {
  content: ""; }

/* line 666, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-times-rectangle:before,
.fa-window-close:before {
  content: ""; }

/* line 667, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: ""; }

/* line 668, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-bandcamp:before {
  content: ""; }

/* line 669, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-grav:before {
  content: ""; }

/* line 670, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-etsy:before {
  content: ""; }

/* line 671, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-imdb:before {
  content: ""; }

/* line 672, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-ravelry:before {
  content: ""; }

/* line 673, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-eercast:before {
  content: ""; }

/* line 674, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-microchip:before {
  content: ""; }

/* line 675, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-snowflake-o:before {
  content: ""; }

/* line 676, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-superpowers:before {
  content: ""; }

/* line 677, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-wpexplorer:before {
  content: ""; }

/* line 678, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_icons.scss */
.fa-meetup:before {
  content: ""; }

/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_screen-reader.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/* line 51, /Users/constant/Repos/1010/resources/assets/admin/css/libs/99-fontawesome/_mixins.scss */
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

/* ----- SimpleMDE - Markdown Editor ----- */
/* line 3, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
.CodeMirror {
  border: 2px solid #DDD; }

/* line 7, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
.CodeMirror:not(.CodeMirror-fullscreen),
.CodeMirror:not(.CodeMirror-fullscreen) .CodeMirror-scroll {
  min-height: 200px;
  max-height: 80vh; }

/* line 13, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
.editor-toolbar {
  border-top: 2px solid #DDD;
  border-left: 2px solid #DDD;
  border-right: 2px solid #DDD;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  opacity: 1; }
  /* line 20, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
  .editor-toolbar a {
    opacity: .6; }

/* ----- Editor preview ----- */
/* line 29, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
.editor-preview-side h1, .editor-preview-side h2, .editor-preview-side h3,
.editor-preview-side h4, .editor-preview-side h5, .editor-preview-side h6 {
  font-weight: bold; }

/* line 34, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
.editor-preview-side h2 {
  font-size: 1.5em; }

/* line 38, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
.editor-preview-side h3 {
  font-size: 1.2em; }

/* ----- réécriture des icones ----- */
/* line 45, /Users/constant/Repos/1010/resources/assets/admin/css/includes/01-markdown-editor.scss */
.fa:before, .note-icon-bold:before, .note-icon-underline:before, .note-icon-eraser:before, .note-icon-unorderedlist:before, .note-icon-orderedlist:before, .note-icon-picture:before, .note-icon-link:before, .note-icon-chain-broken:before, .note-icon-arrows-alt:before, .note-icon-code:before, .note-icon-question:before, .note-icon-magic:before, .note-icon-caret:before, .note-icon-trash:before, .note-icon-italic:before, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
  font-family: FontAwesome; }

/* ----- Login page ----- */
/* line 3, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
.admin.login,
.admin.reset_mail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 0;
  overflow: hidden; }
  /* line 11, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login .form__container,
  .admin.reset_mail .form__container {
    width: 100%;
    margin: 0; }
    @media (min-width: 768px) {
      /* line 11, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
      .admin.login .form__container,
      .admin.reset_mail .form__container {
        width: 350px; } }
  /* line 18, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login .form__body,
  .admin.reset_mail .form__body {
    background-color: white;
    padding: 20px; }
  /* line 22, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login label,
  .admin.reset_mail label {
    display: none; }
  /* line 25, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login .form-control,
  .admin.reset_mail .form-control {
    height: 40px; }
  /* line 28, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login input[type='password'],
  .admin.reset_mail input[type='password'] {
    border-top: 0; }
  /* line 31, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login .help-block + label + input[type='password'],
  .admin.reset_mail .help-block + label + input[type='password'] {
    border: 2px solid #DDD; }
  /* line 34, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login .form-group,
  .admin.reset_mail .form-group {
    margin-bottom: 0; }
  /* line 37, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login .remember,
  .admin.reset_mail .remember {
    display: none; }
  /* line 40, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .admin.login .fantom,
  .admin.reset_mail .fantom {
    position: absolute;
    width: 28px;
    height: 22px;
    background-image: url("images/fantom.gif");
    background-repeat: no-repeat; }

/* line 50, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
.login__header {
  padding: 1px;
  background-color: #0065ff; }

/* line 54, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
.login__title {
  text-align: center;
  font-size: 15px;
  color: white;
  font-weight: 400;
  margin: 15px; }

/* line 61, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
.login__footer {
  margin-top: 20px; }

/* line 64, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
.login__btn {
  text-align: right; }

/* line 67, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
.login__password {
  line-height: 40px; }
  /* line 69, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
  .login__password a {
    color: #777;
    font-size: 10px; }

/* line 76, /Users/constant/Repos/1010/resources/assets/admin/css/includes/02-auth.scss */
.admin.reset_password .panel {
  margin-top: 10%; }

/* line 2, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
nav ul ul {
  padding-left: 10px; }

/* line 5, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
nav li,
nav a {
  position: relative;
  display: block; }

/* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
nav a {
  color: #333; }

/* line 15, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.navbar {
  margin-bottom: 0; }
  /* line 17, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .navbar .active {
    color: #0065ff; }

/* ----- Main navigation ----- */
/* line 24, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.navbar > .container-fluid .navbar-brand {
  padding: 10px;
  background-image: url("images/fantom.gif");
  background-repeat: no-repeat;
  background-position: 5px center;
  text-indent: -9999px;
  width: 50px; }
  /* line 31, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .navbar > .container-fluid .navbar-brand:hover {
    background-image: url("images/fantom_.gif"); }

/* line 36, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.nav-primary {
  z-index: 9;
  background-color: #333;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5); }
  /* line 40, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .nav-primary .nav > li > a:focus,
  .nav-primary .nav > li > a:hover,
  .nav-primary .nav > li > a:active,
  .nav-primary .nav .open > a {
    color: #EFEFEF;
    background-color: #333; }
  /* line 48, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .nav-primary a {
    color: #fff; }
    /* line 50, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
    .nav-primary a:hover {
      text-decoration: underline; }
  /* line 54, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .nav-primary .navbar-toggle {
    border: 0;
    padding: 13px 10px; }
    /* line 57, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
    .nav-primary .navbar-toggle:hover {
      background-color: #EFEFEF; }
    /* line 60, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
    .nav-primary .navbar-toggle .icon-bar {
      background-color: #333; }

/* ----- Sub navigation ----- */
/* line 69, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
ul ul {
  list-style-type: none; }

/* line 74, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.navbar-sub ul {
  padding-left: 53px; }

/* line 77, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.navbar-sub a {
  color: #333; }

/* line 80, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.navbar-sub .nav > li:first-of-type {
  margin-left: -15px; }

/* Hide for mobile, show later */
/* line 86, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.sidebar {
  display: none; }

@media (min-width: 768px) {
  /* line 90, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .sidebar {
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; } }

/* line 103, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.nav-sidebar {
  margin-left: 10px;
  margin-right: -21px;
  margin-bottom: 20px; }
  /* line 107, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .nav-sidebar li > a {
    padding: 5px 15px; }
  /* line 110, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .nav-sidebar a:hover {
    text-decoration: none; }

/* ----- Dropdown menu ----- */
/* line 118, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
.dropdown-menu {
  font-size: font-size-regular;
  color: white;
  background-color: #333;
  border: none;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  /* line 125, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
  .dropdown-menu > li > a {
    color: white; }
    /* line 127, /Users/constant/Repos/1010/resources/assets/admin/css/includes/03-navigation.scss */
    .dropdown-menu > li > a:hover {
      background-color: #333;
      color: #0065ff; }

/* ----- Tables ----- */
/* line 5, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
.table > thead:first-child > tr:first-child > td {
  vertical-align: middle; }

/* line 9, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
.table tbody > tr > td,
.table tbody > tr > th,
.table tfoot > tr > td,
.table tfoot > tr > th,
.table thead > tr > td,
.table thead > tr > th {
  padding: 15px;
  border-top: none; }

/* line 19, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
.table .attribute {
  text-align: right;
  color: #DDD; }

/* line 26, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
tbody tr {
  border-bottom: 1px solid #EFEFEF; }

/* line 29, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
tbody .fa, tbody .note-icon-bold, tbody .note-icon-underline, tbody .note-icon-eraser, tbody .note-icon-unorderedlist, tbody .note-icon-orderedlist, tbody .note-icon-picture, tbody .note-icon-link, tbody .note-icon-chain-broken, tbody .note-icon-arrows-alt, tbody .note-icon-code, tbody .note-icon-question, tbody .note-icon-magic, tbody .note-icon-caret, tbody .note-icon-trash, tbody .note-icon-italic, tbody .select2-container--default .select2-selection--multiple .select2-selection__choice__remove, .select2-container--default .select2-selection--multiple tbody .select2-selection__choice__remove {
  margin-right: 5px; }

/* line 33, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
tbody .published .fa, tbody .published .note-icon-bold, tbody .published .note-icon-underline, tbody .published .note-icon-eraser, tbody .published .note-icon-unorderedlist, tbody .published .note-icon-orderedlist, tbody .published .note-icon-picture, tbody .published .note-icon-link, tbody .published .note-icon-chain-broken, tbody .published .note-icon-arrows-alt, tbody .published .note-icon-code, tbody .published .note-icon-question, tbody .published .note-icon-magic, tbody .published .note-icon-caret, tbody .published .note-icon-trash, tbody .published .note-icon-italic, tbody .published .select2-container--default .select2-selection--multiple .select2-selection__choice__remove, .select2-container--default .select2-selection--multiple tbody .published .select2-selection__choice__remove {
  position: relative;
  top: -1px;
  color: tomato;
  font-size: 10px; }

/* line 39, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
tbody .published-1 .fa, tbody .published-1 .note-icon-bold, tbody .published-1 .note-icon-underline, tbody .published-1 .note-icon-eraser, tbody .published-1 .note-icon-unorderedlist, tbody .published-1 .note-icon-orderedlist, tbody .published-1 .note-icon-picture, tbody .published-1 .note-icon-link, tbody .published-1 .note-icon-chain-broken, tbody .published-1 .note-icon-arrows-alt, tbody .published-1 .note-icon-code, tbody .published-1 .note-icon-question, tbody .published-1 .note-icon-magic, tbody .published-1 .note-icon-caret, tbody .published-1 .note-icon-trash, tbody .published-1 .note-icon-italic, tbody .published-1 .select2-container--default .select2-selection--multiple .select2-selection__choice__remove, .select2-container--default .select2-selection--multiple tbody .published-1 .select2-selection__choice__remove {
  color: LightGreen; }

/* line 42, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
tbody a {
  color: #333; }

/* line 47, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
tfoot {
  border-top: 1px solid #DDD; }
  /* line 49, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
  tfoot > tr > td {
    border-top: none; }

/* ----- Sortable ----- */
/* line 57, /Users/constant/Repos/1010/resources/assets/admin/css/includes/04-tables.scss */
.sortable-ghost {
  opacity: 0; }

/* ----- Alerts ----- */
/* line 5, /Users/constant/Repos/1010/resources/assets/admin/css/includes/05-alerts.scss */
.has-error .control-label {
  color: #0065ff; }

/* line 8, /Users/constant/Repos/1010/resources/assets/admin/css/includes/05-alerts.scss */
.has-error .form-control,
.has-error .form-control:focus {
  border-color: #0065ff;
  box-shadow: none; }

/* line 15, /Users/constant/Repos/1010/resources/assets/admin/css/includes/05-alerts.scss */
.help-block,
.has-error .help-block,
.create .help-block {
  background-color: rgba(255, 255, 0, 0.25);
  color: #777;
  display: inline-block; }
  /* line 21, /Users/constant/Repos/1010/resources/assets/admin/css/includes/05-alerts.scss */
  .help-block strong,
  .has-error .help-block strong,
  .create .help-block strong {
    color: #777;
    font-weight: 400; }

/* line 28, /Users/constant/Repos/1010/resources/assets/admin/css/includes/05-alerts.scss */
.alert-success {
  color: #333;
  background-color: rgba(255, 255, 0, 0.25);
  border: none; }

/* ----- Panels ----- */
/* line 3, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.panel {
  border: none;
  box-shadow: none; }
  /* line 6, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
  .panel > .panel-heading {
    background-color: #fff;
    border-bottom: 3px solid #EFEFEF; }
  /* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
  .panel .tips {
    font-size: 10px;
    color: #777;
    font-weight: 400;
    padding-top: 10px; }
  /* line 16, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
  .panel input[type="submit"] {
    margin-top: 20px; }

/* line 21, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.panel-body.table-responsive {
  padding: 0; }

/* line 25, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.ghost {
  display: none; }

/* line 32, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.media-panel .list-group-item {
  font-size: 11px;
  letter-spacing: normal;
  border: none;
  padding: 10px 0; }
  /* line 37, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
  .media-panel .list-group-item a {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333;
    padding-left: 25px; }
    /* line 45, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
    .media-panel .list-group-item a:hover {
      text-decoration: none; }
    /* line 51, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
    .media-panel .list-group-item a:focus, .media-panel .list-group-item a:active {
      outline: 0;
      text-decoration: none; }
  /* line 57, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
  .media-panel .list-group-item i {
    position: absolute;
    left: 0;
    font-size: 14px;
    line-height: 1;
    padding-right: 10px; }

/* line 66, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.media-panel .m-delete {
  float: right;
  display: inline-block;
  color: #333; }

/* line 71, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.media-panel input[type="file"] {
  display: none; }

/* line 74, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.media-panel .media-add {
  color: #0065ff; }

/* line 80, /Users/constant/Repos/1010/resources/assets/admin/css/includes/06-panels.scss */
.sortable tr:hover {
  cursor: move; }

/* ----- Forms ----- */
/* line 5, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
input::placeholder,
textarea::placeholder {
  color: #DDD !important; }

/* line 10, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
label {
  font-size: 13px; }

/* line 15, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.main-form hr {
  margin: 40px -30px;
  border-top: 2px solid #EFEFEF; }

/* line 19, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.main-form > .panel .panel {
  border-bottom: 2px solid #DDD; }
  /* line 21, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
  .main-form > .panel .panel .panel-heading {
    padding: 0;
    border-bottom: none; }
  /* line 25, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
  .main-form > .panel .panel .panel-body {
    padding: 0; }

/* line 29, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.main-form .list-group {
  margin-bottom: 10px; }

/* line 32, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.main-form .list-group-item {
  padding: 0;
  border: none; }

/* line 41, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.form-group:not(.submit) {
  margin-bottom: 30px; }

/* line 46, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.tip {
  font-weight: 400;
  color: #DDD; }

/* line 52, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.tip.created_at input {
  display: none;
  padding: 10px;
  height: 20px;
  width: 200px;
  text-align: right;
  padding: 0;
  margin: 0; }

/* line 62, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.tip.created_at.show span {
  display: none; }

/* line 65, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.tip.created_at.show input {
  display: inline; }

/* line 71, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.form-control {
  font-family: 'Helvetica neue', sans-serif;
  letter-spacing: 1px;
  border: 2px solid #DDD;
  box-shadow: none;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; }
  /* line 77, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
  .form-control:focus {
    border-color: #0065ff;
    outline: 0;
    box-shadow: none; }

/* line 84, /Users/constant/Repos/1010/resources/assets/admin/css/includes/07-forms.scss */
.slug {
  display: inline-block;
  margin-top: 3px;
  color: #DDD; }

/* ----- Modal ----- */
/* line 4, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal .container-fluid {
  padding-right: 15px;
  padding-left: 15px; }

/* line 8, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal .form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0; }

/* line 12, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal figure img {
  max-width: 100%;
  height: auto; }

/* line 16, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal .form-control {
  background-color: #333;
  border: 2px solid #fff;
  color: white; }

/* line 21, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal .btn {
  background-color: #333;
  color: white; }

/* line 25, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal .btn-primary {
  border-color: white;
  background-color: white;
  color: #333; }

/* line 33, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal-content {
  box-shadow: none;
  border: none;
  background-color: #333;
  color: white; }

/* line 40, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal-backdrop {
  background-color: #EFEFEF; }

/* line 43, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal-backdrop.in {
  opacity: 1; }

/* line 47, /Users/constant/Repos/1010/resources/assets/admin/css/includes/08-modal.scss */
.modal-footer {
  text-align: left; }

/* line 29, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
body {
  font-size: 15px;
  color: #222;
  background: #efefef;
  padding-top: 90px; }

/* line 37, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.container-fluid {
  padding-right: 40px;
  padding-left: 40px; }

/* line 42, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
a,
a:active,
a:hover,
a.active {
  color: #0065ff; }

/* line 49, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
h2 {
  font-size: 13px;
  margin-bottom: 20px;
  font-weight: bold; }

/* ----- Bootstrap hack ----- */
/* line 58, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
* {
  border-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

/* ----- Buttons ----- */
/* line 67, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.btn {
  background-color: #fff;
  padding: .5em 1.2em;
  letter-spacing: 1px;
  transition: 0.1s background-color linear, 0.1s color linear;
  border-width: 2px; }

/* line 75, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: 0; }

/* line 80, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.btn-default {
  border-color: #DDD;
  color: #333; }
  /* line 83, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
  .btn-default:hover, .btn-default:active {
    border-color: #333;
    color: #333;
    background-color: #fff; }

/* line 92, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.btn-primary {
  border-color: #0065ff;
  color: #0065ff; }
  /* line 95, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
  .btn-primary:hover, .btn-primary:active, .btn-primary:active:hover, .btn-primary:focus, .btn-primary:active:focus {
    color: #66a3ff;
    background-color: #fff;
    border-color: #0065ff;
    box-shadow: none; }

/* line 107, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.btn-invert {
  border-color: #0065ff;
  color: white;
  background-color: #0065ff; }
  /* line 111, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
  .btn-invert:hover, .btn-invert:active {
    color: #0065ff;
    background-color: #fff; }

/* line 118, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.form-group.submit .btn {
  margin-right: 10px; }

/* line 123, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.delete-form {
  margin-bottom: 40px; }

/* ----- select2min ----- */
/* line 130, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  border: 2px solid #DDD;
  min-height: 32px; }

/* line 136, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #0065ff;
  border: none;
  color: #fff;
  padding: .2em .5em; }

/* line 144, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.select2-container--default .select2-selection--single .select2-selection__placeholder,
.select2-container--default .select2-search--inline .select2-search__field,
.select2-search__field {
  letter-spacing: 1px;
  color: #DDD; }

/* line 152, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: transparent;
  width: 0;
  margin-right: 18px; }
  /* line 158, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: transparent; }
  /* line 161, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
    color: white; }

/* ----- Custom ----- */
/* line 170, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.panel-heading h3 {
  display: inline;
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-weight: 400; }

/* line 177, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.panel-heading .message {
  font-size: 10px;
  background-color: yellow;
  position: relative; }

/* line 187, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.article-edit .slug {
  display: block;
  margin-top: 3px;
  color: #DDD; }

/* ----- Tabs ----- */
/* line 197, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.nav-tabs {
  border-width: 2px;
  margin-bottom: 20px; }
  /* line 200, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
  .nav-tabs > li {
    margin-bottom: -2px; }

/* line 205, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-width: 2px; }

/* line 211, /Users/constant/Repos/1010/resources/assets/admin/css/main.scss */
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: #0065ff; }
