/* ----- SimpleMDE - Markdown Editor ----- */

.CodeMirror{
  border: 2px solid $greyDark;
}

.CodeMirror:not(.CodeMirror-fullscreen),
.CodeMirror:not(.CodeMirror-fullscreen) .CodeMirror-scroll {
  min-height: 200px;
  max-height: 80vh;
}

.editor-toolbar {
  border-top: 2px solid $greyDark;
  border-left: 2px solid $greyDark;
  border-right: 2px solid $greyDark;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  opacity: 1;
  a{
    opacity: .6;
  }
}

/* ----- Editor preview ----- */


.editor-preview-side{
  h1, h2, h3,
  h4, h5, h6 {
    font-weight: bold;
  }

  h2{
    font-size: 1.5em;
  }

  h3{
    font-size: 1.2em;
  }
}

/* ----- réécriture des icones ----- */

.fa:before{
  font-family: FontAwesome;
}

.note-icon-bold{
  @extend .fa;
  @extend .fa-bold;
}
.note-icon-underline{
  @extend .fa;
  @extend .fa-underline;
}
.note-icon-eraser{
  @extend .fa;
  @extend .fa-eraser;
}
.note-icon-unorderedlist{
  @extend .fa;
  @extend .fa-list-ul;
}
.note-icon-orderedlist{
  @extend .fa;
  @extend .fa-list-ol;
}
.note-icon-picture{
  @extend .fa;
  @extend .fa-image;
}
.note-icon-link{
  @extend .fa;
  @extend .fa-link;
}
.note-icon-chain-broken{
  @extend .fa;
  @extend .fa-unlink;
}
.note-icon-arrows-alt{
  @extend .fa;
  @extend .fa-expand;
}
.note-icon-code{
  @extend .fa;
  @extend .fa-code;
}
.note-icon-question{
  @extend .fa;
  @extend .fa-question;
}
.note-icon-magic{
  @extend .fa;
  @extend .fa-header;
}
.note-icon-caret{
  @extend .fa;
  @extend .fa-caret-down;
}

.note-icon-trash{
  @extend .fa;
  @extend .fa-trash-o;
}

.note-icon-italic{
  @extend .fa;
  @extend .fa-italic;
}
