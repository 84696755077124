// ----- Typography ----- //

$font-family-body:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;

// ----- Fonts ----- //

.font-body{
  font-family: $font-family-body;
  letter-spacing: .05em;
}


// ----- Typesize / scale ----- //

$scale           : 1.414;
$font-size-base  : 15px;

$font-size-xs      : 10px;
$font-size-sm      : 13px;
$font-size-regular : $font-size-base;
$font-size-lg      : $font-size-base * $scale;
$font-size-xl      : $font-size-lg * $scale;

//** Unit-less `line-height`
$line-height      : 1.6;
// for use in components like buttons, labels
$line-height-form : 1.428571429 !default;     // 20/14
$font-res-ratio   : 1.4;                      // responsive ratio



// ----- Main colour palette ----- //

$white            : #fff;
$black            : #333;
$yellow           : rgb(255,255,0);
$yellowLight      : rgba(255,255,0,.25);
$green            : #50E678;
$blue             : #0065ff;
$blueLight        : #66a3ff;
$grey             : #EFEFEF;
$greyDark         : #DDD;
$greyDarker       : #777;
$red              : tomato;
$green            : LightGreen;


// ----- Breakpoints ----- //

// Extra small screen / phone
$screen-xs:                  600px !default;
$screen-xs-min:              $screen-xs !default;

// Small screen / tablet
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  1200px !default;
$screen-md-min:              $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1600px !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// Number of columns in the grid.
$grid-columns:              12 !default;
//Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         40px !default;


// ----- Borders ----- //

$border-width-sm         : 1px;
$border-width-md         : $border-width-sm*2;


// ----- Spaces ----- //

$spacing-xlarge   : 60px;
$spacing-large    : 40px;
$spacing-regular  : 20px;
$spacing-small    : 10px;
$spacing-xsmall   : 5px;
