/* ----- Modal ----- */

.modal{
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
  .form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
  }
  figure img{
    max-width: 100%;
    height: auto;
  }
  .form-control{
    background-color: $black;
    border: 2px solid $white;
    color: white;
  }
  .btn{
    background-color: $black;
    color: white;
  }
  .btn-primary{
    border-color: white;
    background-color: white;
    color: $black;
  }

}

.modal-content {
  box-shadow: none;
  border: none;
  background-color: $black;
  color: white;
}

.modal-backdrop {
  background-color: $grey;
}
.modal-backdrop.in{
  opacity: 1;
}

.modal-footer{
  text-align: left;
}
